import type { FetchContext } from 'ofetch'
import type { Response } from './types'

type Context = FetchContext<Response>

export class LegacyApiError extends Error {
  context: Context
  response?: Response

  constructor(context: Context) {
    const message = context.response?._data?.msg

    super(message)

    this.name = 'LegacyApiError'
    this.context = context
    this.response = context.response?._data
  }
}
