export function money(value: string | number) {
  const appStore = useAppStore()

  const currencySymbol = appStore.merchant.currency.symbol || '₽'
  const number = Number(Number(value).toFixed(2))

  const priceFormatted = new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: Number.isInteger(number) ? 0 : 2,
    maximumFractionDigits: 2,
  }).format(number)

  return `${priceFormatted} ${currencySymbol}`
}
