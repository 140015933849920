import type {
  ECommerceCartItem,
  ECommerceOrder,
  ECommerceProduct,
} from '~/types/services/appMetrica'

export function useAppMetrica() {
  if (!import.meta.client) return

  const { $cordova } = useNuxtApp()
  const appStore = useAppStore()
  const bus = useAppEventBus()
  const currencyCode = computed(() => appStore.merchant.currency.code)

  bus.on('productView', ({ product }) => {
    const ecommerceProduct: ECommerceProduct = {
      sku: String(product.id),
      name: product.name,
      actualPrice: {
        fiat: [product.price, currencyCode.value],
      },
      ...(product.priceOld && {
        originalPrice: {
          fiat: [product.priceOld, currencyCode.value],
        },
      }),
    }

    $cordova.postMessage({
      name: 'AppMetrica',
      event: 'showProductDetailsEvent',
      arguments: [ecommerceProduct],
    })
  })

  bus.on('itemAddedToCart', ({ payload }) => {
    const { product, size, modifiers, quantity } = payload
    const { currentPrice, selectedModifiersPrice } = useProduct(product, {
      selectedModifiers: modifiers,
      selectedSizeId: size?.id,
    })

    const item: ECommerceCartItem = {
      product: {
        sku: String(product.id),
        name: size?.name ? `${product.name} ${size.name}` : product.name,
        actualPrice: {
          fiat: [currentPrice.value, currencyCode.value],
          ...(selectedModifiersPrice.value && {
            internalComponents: [[selectedModifiersPrice.value, 'Добавки']],
          }),
        },
      },
      quantity,
      revenue: {
        fiat: [+(currentPrice.value * quantity).toFixed(2), currencyCode.value],
      },
    }

    $cordova.postMessage({
      name: 'AppMetrica',
      event: 'addCartItemEvent',
      arguments: [item],
    })
  })

  bus.on('orderCreated', ({ order, cart }) => {
    const ecommerceOrder: ECommerceOrder = {
      identifier: String(order.id),
      cartItems: cart.items.map((item) => ({
        product: {
          sku: String(item.id),
          name: item.size?.name ? `${item.name} ${item.size.name}` : item.name,
          actualPrice: {
            fiat: [item.price, currencyCode.value],
          },
          ...(item.priceOld && {
            originalPrice: {
              fiat: [item.priceOld, currencyCode.value],
            },
          }),
        },
        revenue: {
          fiat: [item.sum, currencyCode.value],
        },
        quantity: item.quantity,
      })),
    }

    $cordova.postMessage({
      name: 'AppMetrica',
      event: 'purchaseEvent',
      arguments: [ecommerceOrder],
    })
  })
}
