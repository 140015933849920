import type { Toast } from '~/types'

export default defineNuxtPlugin({
  setup() {
    const toastStore = useToastStore()

    const toast = (data: Partial<Toast>) => {
      toastStore.toasts.push({
        ...data,
        type: data.type || 'default',
      })
    }

    return {
      provide: {
        toast,
      },
    }
  },
})
