import type { SwiperContainer } from 'swiper/element'

export function useSwiper(
  swiperContainerRef: Ref<SwiperContainer | null>,
  options?: SwiperContainer['swiper']['params'],
) {
  const swiper = computed(() => swiperContainerRef.value?.swiper || null)

  function initialize() {
    if (swiperContainerRef.value && options !== undefined) {
      Object.assign(swiperContainerRef.value, options)
      swiperContainerRef.value.initialize()
    }
  }

  onNuxtReady(() => nextTick(initialize))

  return {
    instance: swiper,
  }
}
