export const useTopMailRu = (id: number | string) => {
  const script = `
  var _tmr = window._tmr || (window._tmr = []);
  _tmr.push({id: "${id}", type: "pageView", start: (new Date()).getTime()});
  (function (d, w, id) {
    if (d.getElementById(id)) return;
    var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
    ts.src = "https://top-fwz1.mail.ru/js/code.js";
    var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
    if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
  })(document, window, "tmr-code");
  `

  const noScript = `
  <div><img src="https://top-fwz1.mail.ru/counter?id=${id};js=na" style="border:0;position:absolute;left:-9999px" alt="" /></div>
  `

  useHead({
    script: [{ innerHTML: script }],
    noscript: [{ innerHTML: noScript }],
  })
}
