import { Api } from '~/api'
import { LegacyApi } from '~/api/legacy'
import { ModernApi } from '~/api/modern'

export default defineNuxtPlugin({
  setup() {
    const config = useRuntimeConfig()
    const appStore = useAppStore()
    const orderStore = useOrderStore()
    const authStore = useAuthStore()

    const modernApi = new ModernApi(config.public.MODERN_BACKEND)
    const legacyApi = new LegacyApi(config.public.LEGACY_BACKEND)

    const api = new Api({ modernApi, legacyApi })

    watchEffect(
      () => {
        legacyApi.setParams({
          device_id: appStore.deviceId,
          device_platform: appStore.devicePlatform,
          token: authStore.token,
          delivery_date: orderStore.form.asap ? null : orderStore.form.date,
          delivery_time: orderStore.form.asap ? null : orderStore.form.time,
          ...(appStore.merchantLoaded && {
            merchant_keys: appStore.merchant.merchantKey,
            transaction_type: orderStore.form.orderTypeCode,
            point_delivery: orderStore.isPickup
              ? orderStore.form.pickupPointId
              : null,
            zone: orderStore.isDelivery ? orderStore.form.address.zone : null,
            selected_lat: orderStore.isDelivery
              ? orderStore.form.address.coords?.[1]
              : null,
            selected_lng: orderStore.isDelivery
              ? orderStore.form.address.coords?.[0]
              : null,
          }),
        })
      },
      { flush: 'sync' },
    )

    watchEffect(() => {
      modernApi.merchantKey = appStore.merchant.merchantKey
    })

    return {
      provide: {
        api,
      },
    }
  },
})
