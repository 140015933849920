import type {
  Api as ApiInterface,
  GeocodePayload,
  GeocodeResult,
  Suggest,
  GetSuggestionsPayload,
  TelegramAuthStatus,
  GetNotificationsPayload,
  GetDeliveryZonePayload,
  SaveCustomerAddressPayload,
} from '~/types/api'
import type {
  AgreementPage,
  ArticleList,
  Banner,
  Branch,
  CustomPage,
  DeliveryZone,
  Merchant,
  MerchantSettings,
  PrivacyPage,
  PromotionDetails,
  PromotionList,
  Seo,
} from '~/types/merchant'
import type {
  LoginByConfirmationCode,
  SendConfirmationCode,
  LoginByPassword,
  ForgotPasswordPayload,
  ForgotPasswordResponse,
  RegistrationPayload,
} from '~/types/auth'
import type { ModernApi } from './modern'
import type { LegacyApi } from './legacy'
import type * as ModernTypes from './modern/types'
import type * as LegacyAction from './legacy/types/actions'
import type { Id } from '~/types'
import type { Category, Product, Showcase } from '~/types/catalog'
import {
  getOrderStatusColor,
  makeCartItem,
  makeProduct,
  makePromocode,
  makeSelectedGift,
  normalizePhoneNumber,
  removeEmpty,
} from './legacy/utils'
import type {
  CreateOrderPayload,
  CreatedOrder,
  Order,
  OrderDate,
  OrderDetails,
  OrderList,
  OrderTime,
  PaymentType,
} from '~/types/order'
import { LegacyApiError } from './legacy/error'
import type {
  AddToCartPayLoad,
  CustomerRegisterPayload,
} from './legacy/types/payloads'
import { makeMerchant } from './modern/utils'
import fetchJsonp from 'fetch-jsonp'
import qs from 'qs'
import type { GeocodeResponse, GeoSuggest } from '~/types/services/yandex'
import type { Response } from './legacy/types'
import type {
  CustomerAddressList,
  CustomerBonuses,
  CustomerProfile,
  Notification,
  UpdateCustomerProfilePayload,
} from '~/types/customer'
import type {
  Cart,
  AddItemToCartPayload,
  ChangeQuantityPayload,
  Gift,
  GiftList,
  CartRecommendations,
  CartRecommendation,
} from '~/types/cart'
import dayjs from 'dayjs'

interface Apis {
  modernApi: ModernApi
  legacyApi: LegacyApi
}

export class Api implements ApiInterface {
  modernApi: ModernApi
  legacyApi: LegacyApi

  constructor(apis: Apis) {
    this.modernApi = apis.modernApi
    this.legacyApi = apis.legacyApi
  }

  async getMerchantByDomain(domain: string): Promise<Merchant> {
    const response = await this.modernApi.fetch<{ data: ModernTypes.Merchant }>(
      'api/merchant/by-domain',
      { query: { domain } },
    )

    return makeMerchant(response.data)
  }

  async getMerchantByKey(merchantKey: string): Promise<Merchant> {
    const response = await this.modernApi.fetch<{
      data: ModernTypes.Merchant
    }>('api/merchant', {
      headers: { merchantKey },
    })

    return makeMerchant(response.data)
  }

  async getBranchesByDomain(domain: string): Promise<Branch[]> {
    const response = await this.modernApi.fetch<{
      data: ModernTypes.Merchant['branches']
    }>('api/merchant/by-domain/branches', { query: { domain } })

    return response.data || []
  }

  async getCustomPage(id: Id): Promise<CustomPage> {
    const response = await this.legacyApi.fetch<LegacyAction.GetDetailPage>(
      'singlemerchant/api/getDetailPage',
      { query: { id } },
    )

    return {
      id: response.details.data.id,
      name: response.details.data.page_name,
      content: response.details.data.content,
      seo: removeEmpty({
        title: response.details.data.seo_title,
        description: response.details.data.meta_description,
        keywords: response.details.data.meta_keywords,
      }),
    }
  }

  async getBanners(platform: 'desktop' | 'mobile'): Promise<Banner[]> {
    const response = await this.legacyApi.fetch<LegacyAction.LoadSliders>(
      'singlemerchant/api/loadSliders',
    )

    return response.details.data
      .map((item) => ({
        id: item.id,
        name: item.page_name,
        url: item.page_url,
        image: platform === 'mobile' ? item.photo_mobile : item.photo,
      }))
      .filter((banner): banner is typeof banner & { image: string } =>
        Boolean(banner.image),
      )
  }

  async getShowcase(): Promise<Showcase> {
    const response = await this.legacyApi.fetch<LegacyAction.GetHomeProducts>(
      'singlemerchant/api/getHomeProducts',
      { query: { full: true } },
    )

    return {
      categories: response.details.data.map((category) => ({
        id: category.id,
        name: category.name,
        slug: category.code,
        products: Object.values(category.items).map(makeProduct),
      })),
    }
  }

  async getProducts(
    category: Category,
  ): Promise<{ products: Product[]; seo: Seo }> {
    const endpoint = 'singlemerchant/api/loadItemByCategory'

    try {
      const response =
        await this.legacyApi.fetch<LegacyAction.LoadItemByCategory>(endpoint, {
          query: { cat_id: category.id },
        })

      return {
        products: response.details.data.map(makeProduct),
        seo: removeEmpty({
          title: response.details.seo?.seo_title,
          description: response.details.seo?.seo_description,
          keywords: response.details.seo?.seo_keywords,
        }),
      }
    } catch (error) {
      if (
        error instanceof LegacyApiError &&
        error.response?.code === 4 &&
        category.children?.length
      ) {
        /* NOTE: родительская категория пуста, но есть товары в дочерних */

        const response = error.response as LegacyAction.LoadItemByCategory
        const seo = response.details.seo || {}

        const responses = await Promise.all(
          category.children.map((category) =>
            this.legacyApi.fetch<LegacyAction.LoadItemByCategory>(endpoint, {
              query: { cat_id: category.id },
            }),
          ),
        )

        return {
          products: responses.flatMap((response) =>
            response.details.data.map(makeProduct),
          ),
          seo: removeEmpty({
            title: seo.seo_title,
            description: seo.seo_description,
            keywords: seo.seo_keywords,
          }),
        }
      }

      throw error
    }
  }

  async getCart(): Promise<Cart | null> {
    try {
      const response = await this.legacyApi.fetch<LegacyAction.LoadCart>(
        'singlemerchant/api/loadCart',
      )

      return {
        itemsCostWithoutDiscount: Number(response.details.basket_total) || 0,
        itemsCostWithDiscount:
          Number(response.details.basket_total_with_discount) || 0,
        items: response.details.data.item.map((item, index) => ({
          ...makeCartItem(item),
          index,
        })),
        discount: Number(response.details.basket_discount) || 0,
        total: Number(response.details.data.total.total) || 0,
        bonusesEarned: response.details.points_earn,
        bonusesSpent: Number(response.details.cart_details.points_apply) || 0,
        bonunesAvailable:
          response.details.bonuses_max ||
          Number(
            Array.from(
              response.details.available_points_label?.matchAll(
                /-?\d+(\.\d+)?/g,
              ) || [],
            ).reverse()[0]?.[0],
          ) ||
          null,
        promotions: response.details.sales_control_output || null,
        selectedGift: makeSelectedGift(response.details.gift_cart),
        promocode: makePromocode(response.details.cart_details.voucher_details),
        deliveryCost: Number(response.details.data.total.delivery_charges) || 0,
        errors: Array.isArray(response.details.cart_error)
          ? [...new Set(response.details.cart_error)]
          : null,
      }
    } catch {
      return null
    }
  }

  async addItemToCart({
    product,
    size,
    quantity,
    modifiers,
  }: AddItemToCartPayload): Promise<unknown> {
    const payload: AddToCartPayLoad = {
      item_id: product.id,
      price: size ? `${product.price}|${size.name}|${size.id}` : product.price,
      qty: quantity,
      addon_qty: {},
      sub_item: {},
    }

    if (modifiers?.size) {
      modifiers.forEach((modifiers, group) => {
        payload.addon_qty[group.id] ??= []
        payload.sub_item[group.id] ??= []

        modifiers.forEach(({ quantity: modQuantity }, modifier) => {
          if (!quantity || !modQuantity) return

          payload.addon_qty[group.id]?.push(quantity * modQuantity)
          payload.sub_item[group.id]?.push(
            `${modifier.id}|${modifier.price}|${modifier.name}`,
          )
        })
      })
    }

    await this.legacyApi.fetch('singlemerchant/api/addToCart', {
      method: 'POST',
      body: payload,
    })

    return
  }

  async changeQuantity(payload: ChangeQuantityPayload): Promise<unknown> {
    await this.legacyApi.fetch('singlemerchant/api/updateCart', {
      method: 'POST',
      body: {
        row: payload.item.index,
        qty: payload.quantity,
      },
    })

    return
  }

  async clearCart(): Promise<unknown> {
    await this.legacyApi.fetch('singlemerchant/api/clearCart')

    return
  }

  async getProduct(id: Id): Promise<Product> {
    const response = await this.legacyApi.fetch<LegacyAction.LoadItemDetails>(
      'singlemerchant/api/loadItemDetails',
      { query: { item_id: id } },
    )

    return makeProduct(response.details.data)
  }

  async getSuggestions(payload: GetSuggestionsPayload): Promise<Suggest[]> {
    const queryParams = {
      bases: 'geo',
      origin: 'jsapi2Geocoder',
      fullpath: '1',
      lang: 'ru_RU',
      pos: '10',
      v: '9',
      part: payload.query,
      ...(payload.coords && {
        ll: payload.coords.join(','),
        spn: '2,2',
      }),
    }

    const url =
      'https://suggest-maps.yandex.ru/suggest-geo?' + qs.stringify(queryParams)

    const response = await fetchJsonp(url).then((r) => r.json())

    return (response.results as GeoSuggest[])
      .filter((item) => {
        return Boolean(
          item.text &&
            !item.tags.includes('query') &&
            !item.tags.includes('entrance'),
        )
      })
      .map((item) => ({
        addressLine: item.text,
        title: item.title.text,
        isValidAddress: item.tags.includes('house'),
      }))
  }

  async geocode(payload: GeocodePayload): Promise<GeocodeResult> {
    let geocode: string

    if ('query' in payload) {
      geocode = payload.query
    } else {
      geocode = payload.coords.join(',')
    }

    let response: GeocodeResponse

    if (window.ymaps.geocode) {
      response = (await window.ymaps.geocode(geocode, {
        json: true,
        results: 1,
      })) as unknown as GeocodeResponse
    } else {
      response = (
        await $fetch<{ response: GeocodeResponse }>(
          'https://geocode-maps.yandex.ru/1.x',
          {
            query: {
              apikey: payload.apiKey,
              format: 'json',
              results: 1,
              geocode,
            },
          },
        )
      ).response
    }

    const geoObject = response.GeoObjectCollection.featureMember[0]?.GeoObject

    if (!geoObject) throw new Error('geocode error')

    const components =
      geoObject.metaDataProperty.GeocoderMetaData.Address.Components

    const coords = geoObject.Point.pos.split(' ').map((value) => Number(value))

    return {
      coords: coords,
      addressLine: geoObject.metaDataProperty.GeocoderMetaData.text,
      title: geoObject.name,
      city: components.find(({ kind }) => kind === 'locality')?.name,
      street:
        components.find(({ kind }) => kind === 'street')?.name ||
        components.find(({ kind }) => kind === 'district')?.name,
      house: components.find(({ kind }) => kind === 'house')?.name,
    }
  }

  async getPaymentTypes(): Promise<PaymentType[]> {
    const response = await this.legacyApi.fetch<LegacyAction.LoadPaymentList>(
      'singlemerchant/api/loadPaymentList',
    )

    return response.details.data
      .map((item) => ({
        name: item.payment_name,
        code: item.payment_code,
        cash: Boolean(item.order_change),
      }))
      .filter((item) => !['gpay', 'apay'].includes(item.code))
  }

  async getOrderDateList(): Promise<OrderDate[]> {
    const response = await this.legacyApi.fetch<LegacyAction.DeliveryDateList>(
      'singlemerchant/api/deliveryDateList',
    )

    return Object.entries(response.details.data).map(([key, value]) => ({
      label: value,
      value: key,
    }))
  }

  async getOrderTimeList(date: OrderDate): Promise<OrderTime[]> {
    const response = await this.legacyApi.fetch<LegacyAction.DeliveryTimeList>(
      'singlemerchant/api/deliveryTimeList',
      { query: { delivery_date: date.value } },
    )

    return response.details.data.map((item) => ({
      label: item,
      value: item,
    }))
  }

  async createOrder({ form }: CreateOrderPayload): Promise<CreatedOrder> {
    const payload: Record<string, unknown> = {
      transaction_type: form.orderTypeCode,
      contact_name: form.contactName,
      contact_phone: form.contactPhone,
      ...(form.asap
        ? {
            delivery_asap: form.asap,
            delivery_date: dayjs().format('YYYY-MM-DD'),
          }
        : { delivery_date: form.date, delivery_time: form.time }),
      comment: form.comment,
      payment_provider: form.paymentTypeCode,
      order_change: form.needChange,
      call_me: form.call,
      count_person: form.persons,
      ...form.utm,
    }

    if (form.orderTypeCode === 'pickup') {
      payload.point_delivery = form.pickupPointId
    }

    if (form.orderTypeCode === 'dinein') {
      payload.dinein_table_number = ''
      payload.point_delivery = form.dineinPointId
      payload.dinein_number_of_guest = form.persons
      payload.dinein_special_instruction = form.comment
    }

    if (form.orderTypeCode === 'delivery') {
      const comment = [form.address.comment, form.comment]
        .filter(Boolean)
        .join(' | ')

      payload.comment = comment

      const addressPayload: Record<string, unknown> = {
        // contact_name: form.contactName,
        contact_phone: form.contactPhone,
        count_person: form.persons,
        street: [form.address.city, form.address.street]
          .filter(Boolean)
          .join(', '),
        state: form.address.house,
        zipcode: form.address.flat,
        pod: form.address.entrance,
        et: form.address.floor,
        domofon: form.address.doorphone,
        housing: form.address.building,
        delivery_instruction: comment,
      }

      await this.legacyApi.fetch<LegacyAction.PayNow>(
        'singlemerchant/api/setDeliveryAddress',
        { query: addressPayload },
      )
    }

    const response = await this.legacyApi.fetch<LegacyAction.PayNow>(
      'singlemerchant/api/payNow',
      { query: payload },
    )

    return {
      id: response.details.order_id,
      paymentUrl: response.details.redirect_url,
    }
  }

  async getOrder(id: Id): Promise<Order> {
    const response = await this.legacyApi.fetch<Response<{ status: string }>>(
      'singlemerchant/rest/order/get',
      {
        query: { id },
      },
    )

    return {
      status: response.details.status,
      statusColor: getOrderStatusColor(response.details.status),
    }
  }

  async checkTelegramAuthStatus(): Promise<TelegramAuthStatus> {
    try {
      const response = await this.legacyApi.fetch<LegacyAction.TelegramStatus>(
        'singlemerchant/api/telegram_status',
      )

      return {
        token: response.details.token,
      }
    } catch {
      return {
        // token: '1mdpqctzxn3wamp1e715c1ed5cd5b63669329eb4c5d13d8',
        token: null,
      }
    }
  }

  async getCustomerProfile(): Promise<CustomerProfile> {
    const response = await this.legacyApi.fetch<LegacyAction.GetUserProfile>(
      'singlemerchant/api/getUserProfile',
    )

    return {
      phoneNumber: response.details.data.contact_phone,
      birthday: response.details.data.birthday,
      email: response.details.data.email_address,
      firstName: response.details.data.first_name,
      lastName: response.details.data.last_name,
    }
  }

  async sendConfirmationCode(payload: SendConfirmationCode): Promise<unknown> {
    await this.legacyApi.fetch<LegacyAction.SendConfirmationCode>(
      'singlemerchant/api/sendConfirmationCode',
      {
        query: {
          phone: normalizePhoneNumber(payload.phoneNumber),
        },
        headers: {
          Authorization: payload.recaptchaToken,
        },
      },
    )

    return
  }

  async loginByConfirmationCode(
    credentials: LoginByConfirmationCode,
  ): Promise<{ token: string }> {
    const response =
      await this.legacyApi.fetch<LegacyAction.LoginByPhoneAndCode>(
        'singlemerchant/api/loginByPhoneAndCode',
        {
          query: {
            phone: normalizePhoneNumber(credentials.phoneNumber),
            code: credentials.code,
          },
        },
      )

    return {
      token: response.details.token,
    }
  }

  async loginByPassword(
    credentials: LoginByPassword,
  ): Promise<{ token: string }> {
    const response = await this.legacyApi.fetch<LegacyAction.Login>(
      'singlemerchant/api/login',
      {
        query: {
          username: normalizePhoneNumber(credentials.phoneNumber),
          password: credentials.password,
        },
      },
    )

    return {
      token: response.details.token,
    }
  }

  async forgotPassword(
    payload: ForgotPasswordPayload,
  ): Promise<ForgotPasswordResponse> {
    const response = await this.legacyApi.fetch<LegacyAction.RequestForgotPass>(
      'singlemerchant/api/requestForgotPass',
      {
        query: {
          user_email: normalizePhoneNumber(payload.phoneNumber),
        },
        headers: {
          Authorization: payload.recaptchaToken,
        },
      },
    )

    return {
      message: response.msg,
    }
  }

  async registration(payload: RegistrationPayload): Promise<{ token: string }> {
    const query: CustomerRegisterPayload = {
      contact_phone: payload.phoneNumber,
      password: payload.password,
      cpassword: payload.passwordConfirm,
    }

    const response = await this.legacyApi.fetch<LegacyAction.CustomerRegister>(
      'singlemerchant/api/customerRegister',
      {
        query,
        headers: {
          Authorization: payload.recaptchaToken,
        },
      },
    )

    return {
      token: response.details.token,
    }
  }

  async getAgreementPage(): Promise<AgreementPage> {
    const response = await this.legacyApi.fetch<LegacyAction.UserDataObserve>(
      'singlemerchant/api/user_data_observe',
    )

    return {
      seo: removeEmpty({
        title: '',
        description: '',
        keywords: '',
      }),
      content: response.details.user_data_observe,
    }
  }

  async getPrivacyPage(): Promise<PrivacyPage> {
    const response = await this.legacyApi.fetch<LegacyAction.LoadPrivacy>(
      'singlemerchant/api/loadPrivacy',
    )

    return {
      seo: {
        ...removeEmpty(response.details.seo),
      },
      content: response.details.data[0]?.html_privacy || '',
    }
  }

  async updateCustomerProfile(
    payload: UpdateCustomerProfilePayload,
  ): Promise<{ message: string }> {
    const birthday = payload.birthday
      ? dayjs(payload.birthday, 'DD.MM.YYYY', true)
      : null

    if (birthday && !birthday.isValid()) {
      throw new Error('Неверно указан день рождения')
    }

    const response = await this.legacyApi.fetch<Response>(
      'singlemerchant/api/saveProfile',
      {
        query: {
          first_name: payload.firstName,
          email_address: payload.email,
          birthday: birthday?.format('YYYY-MM-DD'),
        },
      },
    )

    return {
      message: response.msg,
    }
  }

  async getOrderList(): Promise<OrderList> {
    try {
      const response = await this.legacyApi.fetch<LegacyAction.GetOrders>(
        'singlemerchant/api/getOrders',
      )

      return response.details.data.map((item) => ({
        id: item.order_id,
        type: item.trans_type,
        status: item.status,
        statusColor: getOrderStatusColor(item.status),
        total: Number(item.total.replace(/[^0-9,.]/g, '')),
        paymentLabel: item.payment_type,
        deliveryDate: dayjs(item.delivery_date, 'YYYY-MM-DD').format(
          'DD.MM.YYYY',
        ),
        deliveryTime: item.delivery_time,
      }))
    } catch {
      return []
    }
  }

  async getOrderDetails(id: Id): Promise<OrderDetails> {
    const response = await this.legacyApi.fetch<LegacyAction.GetOrderDetails>(
      'singlemerchant/api/getOrderDetails',
      { query: { id } },
    )

    return {
      id: response.details.order_data.order_id,
      asap: Boolean(response.details.order_data.delivery_asap),
      deliveryDate: dayjs(
        response.details.order_data.delivery_date,
        'YYYY-MM-DD',
      ).format('DD.MM.YYYY'),
      deliveryTime: response.details.order_data.delivery_time,
      status: response.details.order_data.status,
      statusColor: getOrderStatusColor(response.details.order_data.status),
      bonusesEarned: Number(response.details.bonuses_earned) || 0,
      bonusesSpent: Number(response.details.bonuses_spent) || 0,
      total: Number(response.details.order_data.total_w_tax) || 0,
      type: response.details.order_data.trans_type,
      items: response.details.items.map((item) => ({
        id: item.item_id,
        name: item.item_name,
        sizeName: item.size_words,
        price: Number(item.discounted_price || item.normal_price) || 0,
        quantity: item.qty,
        sum: Number(item.total_price) || 0,
      })),
    }
  }

  async getPromotions(): Promise<PromotionList> {
    const response = await this.legacyApi.fetch<LegacyAction.LoadSales>(
      'singlemerchant/api/loadSales',
    )

    return {
      seo: removeEmpty({ ...response.details.seo }),
      promotions: response.details.data.map((item) => ({
        id: item.id,
        name: item.page_name,
        description: item.detail_text,
        image: item.photo,
      })),
    }
  }

  async getPromotionDetails(id: Id): Promise<PromotionDetails> {
    const response = await this.legacyApi.fetch<LegacyAction.GetDetailSales>(
      'singlemerchant/api/getDetailSales',
      { query: { id } },
    )

    return {
      id: response.details.data.id,
      name: response.details.data.page_name,
      description: response.details.data.detail_text,
      content: response.details.data.content,
      image: response.details.data.photo,
      seo: removeEmpty({
        title: response.details.data.seo_title,
        description: response.details.data.meta_description,
        keywords: response.details.data.meta_keywords,
      }),
    }
  }

  async getCustomerBonuses(): Promise<CustomerBonuses> {
    const response = await this.legacyApi.fetch<LegacyAction.PointsSummary>(
      'singlemerchant/api/pointsSummary',
    )

    return {
      balance: Number(response.details.total_available) || 0,
    }
  }

  async applyBonuses(value: string | number): Promise<unknown> {
    await this.legacyApi.fetch('singlemerchant/api/applyRedeemPoints', {
      query: { points: value },
    })

    return
  }

  async cancelBonuses(): Promise<unknown> {
    await this.legacyApi.fetch('singlemerchant/api/removePoints')

    return
  }

  async getGiftList(): Promise<GiftList | null> {
    try {
      const response = await this.legacyApi.fetch<LegacyAction.GiftList>(
        'singlemerchant/api/giftList',
      )

      if (!response.details.items.length) {
        return null
      }

      const gifts: Gift[] = response.details.items
        .map((item) => ({
          id: item.item_id,
          name: item.name,
          image: item.photo,
          available: Boolean(item.available),
          description: item.description,
          progressPercent: item.progress_percent,
          requiredSum: Number(item.presents_summ) || 0,
          remaining: Number(item.presents_to) || 0,
        }))
        .sort((a, b) => a.requiredSum - b.requiredSum)

      return {
        currentLevel: response.details.context.current_level,
        progressPercent: response.details.context.delta_level,
        gifts,
        levels: gifts
          .reduce((acc, cur) => {
            const index = acc.findIndex(
              (item) => item.requiredSum == cur.requiredSum,
            )
            if (index === -1) {
              acc.push(cur)
            }
            return acc
          }, [] as Gift[])
          .map((item, i) => ({
            number: i + 1,
            progressPercent: item.progressPercent,
            requiredSum: item.requiredSum,
            remaining: item.remaining,
          })),
      }
    } catch {
      return null
    }
  }

  async selectGift(id: Id): Promise<unknown> {
    await this.legacyApi.fetch('singlemerchant/api/addGiftToCart', {
      method: 'POST',
      body: {
        item_id: id,
      },
    })

    return
  }

  async cancelGift(id: Id): Promise<unknown> {
    await this.legacyApi.fetch('singlemerchant/api/demolishGiftFromCart', {
      method: 'POST',
      body: {
        item_id: id,
      },
    })

    return
  }

  async applyPromocode(name: string): Promise<unknown> {
    await this.legacyApi.fetch('singlemerchant/api/applyVoucher', {
      query: { voucher_name: name },
    })

    return
  }

  async cancelPromocode(): Promise<unknown> {
    await this.legacyApi.fetch('singlemerchant/api/removeVoucher')

    return
  }

  async getCartRecommendations(): Promise<CartRecommendations | null> {
    try {
      const response = await this.legacyApi.fetch<LegacyAction.RecommendCart>(
        'singlemerchant/api/recommendCart',
      )

      const groups = response.details
        .map((group) => ({
          name: group.name,
          type: group.type,
          items: Object.values(group.items).map(
            (item): CartRecommendation => ({
              id: item.id,
              name: item.name,
              image: item.img,
              price: Number(item.price) || 0,
              quantity: item.qty,
              discount: Number(item.discount) || null,
              hasModifiers: Boolean(item.has_modifier),
            }),
          ),
        }))
        .filter((group) => group.items.length)

      return groups.length ? groups : null
    } catch {
      return null
    }
  }

  async getNotifications(
    payload?: GetNotificationsPayload,
  ): Promise<Notification[]> {
    const response = await this.legacyApi.fetch<LegacyAction.LoadNotification>(
      'singlemerchant/api/loadNotification',
      { query: { push_data: payload?.type } },
    )

    return response.details.data.map((item) => ({
      date: item.date_created,
      title: item.push_title,
      message: item.push_message,
    }))
  }

  async getArticles(): Promise<ArticleList> {
    const response = await this.legacyApi.fetch<LegacyAction.GetArticles>(
      'singlemerchant/api/getArticles',
    )

    return {
      articles: response.details.data.map((item) => ({
        id: item.id,
        name: item.name,
        description: item.short_description,
        image: item.photo,
        content: item.description,
        seo: removeEmpty({
          title: item.seo.seo_title,
          description: item.seo.meta_description,
          keywords: item.seo.meta_keywords,
        }),
      })),
      seo: removeEmpty(response.details.seo),
    }
  }

  async getDeliveryZone(
    payload: GetDeliveryZonePayload,
  ): Promise<DeliveryZone> {
    const response = await this.legacyApi.fetch<LegacyAction.GeoAdressDelivery>(
      'singlemerchant/api/geoAdressDelivery',
      {
        query: {
          query: payload.addressLine,
          preparatory_check: Boolean(payload.preview),
        },
      },
    )

    if ('zone' in response.details) {
      return {
        name: response.details.zone,
        deliveryCost: Number(response.details.delivery_price) || 0,
        deliveryTime: response.details.delivery_time,
        freeDeliverySum: Number(response.details.free_delivery) || 0,
        minOrderSum: Number(response.details.min_price) || 0,
        payments: response.details.payments?.map((item) => ({
          name: item.payment_name,
          code: item.payment_code,
          cash: Boolean(item.order_change),
        })),
      }
    }

    throw new Error(response.details.text)
  }

  async getCustomerAddressList(): Promise<CustomerAddressList> {
    const response =
      await this.legacyApi.fetch<LegacyAction.GetAddressBookList>(
        'singlemerchant/api/getAddressBookList',
      )

    return response.details.data.map((item) => {
      const title = [item.street, item.state].filter(Boolean).join(', ')

      return {
        id: item.id,
        name: item.location_name || item.address_line || title,
        city: item.city,
        street: item.street,
        addressLine: item.address_line,
        title: title,
        coords: item.coords && JSON.parse(item.coords).reverse(),
        floor: item.et,
        doorphone: item.domofon,
        house: item.state,
        flat: item.zipcode,
        building: item.housing,
        entrance: item.pod,
        comment: item.comment,
      }
    })
  }

  async saveCustomerAddress(
    address: SaveCustomerAddressPayload,
  ): Promise<unknown> {
    await this.legacyApi.fetch('singlemerchant/api/saveAddressBook', {
      query: {
        book_id: address.id,
        location_name: address.name,
        address_line: address.addressLine,
        city: address.city,
        street: address.street,
        state: address.house,
        housing: address.building,
        pod: address.entrance,
        domofon: address.doorphone,
        et: address.floor,
        zipcode: address.flat,
        coords: address.coords
          ? JSON.stringify([...address.coords].reverse())
          : null,
        comment: address.comment,
      },
    })

    return
  }

  async deleteCustomerAddress(id: Id): Promise<unknown> {
    await this.legacyApi.fetch('singlemerchant/api/deleteAddressBook', {
      query: { id },
    })

    return
  }

  async getMerchantSettings(): Promise<MerchantSettings> {
    const response = await this.legacyApi.fetch<LegacyAction.GetAppSettings>(
      'singlemerchant/api/getAppSettings',
    )

    const { shop_settings } = response.details

    return {
      statuses: {
        weekend: {
          active: shop_settings.closed_holiday_enabled,
          message: shop_settings.closed_holiday_msg || 'Сегодня выходной',
          image: shop_settings.closed_holiday_img,
        },
        merchantClosed: {
          active: shop_settings.closed_shop_enabled,
          message: shop_settings.closed_shop_msg || 'Магазин закрыт',
          image: shop_settings.closed_shop_img,
        },
        ordersDisabled: {
          active: shop_settings.disabled_order_enabled,
          message: shop_settings.disabled_order_msg || 'Приём заказов отключён',
          image: shop_settings.disabled_order_img,
        },
        notWorkingHours: {
          active: shop_settings.merchant_break,
          message:
            shop_settings.merchant_break_msg || 'Сейчас магазин не работает',
          image: shop_settings.merchant_break_img,
        },
        busyKitchen: {
          active: Boolean(
            (shop_settings.kitchen_show_now &&
              shop_settings.kitchen_pressure) ||
              response.details.is_pressure_time,
          ),
          message:
            response.details.shop_settings.kitchen_text ||
            'Большая нагрузка на кухню',
          image: response.details.shop_settings.kitchen_img,
        },
      },
    }
  }
}
