import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeRu from 'dayjs/locale/ru'

export default defineNuxtPlugin({
  setup() {
    dayjs.extend(customParseFormat)
    dayjs.locale(localeRu)

    return {
      provide: {
        dayjs,
      },
    }
  },
})
