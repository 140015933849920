import chroma from 'chroma-js'

export default defineNuxtPlugin(() => {
  const appStore = useAppStore()

  if (!appStore.merchantLoaded) return

  const theme = computed(() => appStore.merchant.theme)

  const primaryColor = computed<string>(() =>
    chroma.valid(theme.value.primaryColor)
      ? chroma(theme.value.primaryColor).hex()
      : '#5DB631',
  )

  const cssVars = computed(() => {
    const values: Record<string, string> = {
      '--theme-font-family': `"${theme.value.fontFamily}"`,
      '--theme-color-primary': primaryColor.value,
      '--theme-color-primary-rgb': chroma(primaryColor.value).rgb().join(' '),
    }

    return values
  })

  const css = computed(() => {
    const props = Object.entries(cssVars.value)
      .map(([key, value]) => `${key}:${value}`)
      .join(';')

    return `:root{${props}}`
  })

  useHead(() => ({
    htmlAttrs: {
      class: theme.value.dark ? 'dark' : 'light',
    },
    style: [{ innerHTML: css.value }],
    link: [
      {
        rel: 'preconnect',
        href: 'https://fonts.googleapis.com',
      },
      {
        rel: 'stylesheet',
        href: `https://fonts.googleapis.com/css2?family=${encodeURI(theme.value.fontFamily)}:wght@400;700&display=swap`,
        crossorigin: '',
      },
    ],
  }))

  return {
    provide: {
      primaryColor,
    },
  }
})
