export const useYandexMetrica = (counterId: number) => {
  const script = `
  (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
  m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
  (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
  `

  useHead({
    script: [
      {
        innerHTML: script,
      },
    ],
  })

  onNuxtReady(() => {
    ym(counterId, 'init', {
      ecommerce: true,
      webvisor: true,
    })

    useScriptEventPage(({ title, path }) => {
      ym(counterId, 'hit', path, { title })
    })

    const bus = useAppEventBus()

    bus.on('orderCreated', ({ order }) => {
      ym(counterId, 'reachGoal', 'order-submit', { orderId: order.id })
    })

    bus.on('customerRegistered', () => {
      ym(counterId, 'reachGoal', 'client-register')
    })

    bus.on('downloadAppBannerClicked', () => {
      ym(counterId, 'reachGoal', 'click-banner-apps')
    })

    bus.on('downloadAppButtonClicked', () => {
      ym(counterId, 'reachGoal', 'click-mobile-apps')
    })

    bus.on('merchantContactPhoneClicked', () => {
      ym(counterId, 'reachGoal', 'click-phone')
    })

    bus.on('itemAddedToCart', () => {
      ym(counterId, 'reachGoal', 'add-to-basket')
    })

    bus.on('cartView', () => {
      ym(counterId, 'reachGoal', 'cart')
    })
  })
}
