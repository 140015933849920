import type { CustomerBonuses, CustomerProfile } from '~/types/customer'

export const useCustomerStore = defineStore('customer', () => {
  const profile = ref<CustomerProfile | null>()

  async function fetchProfile() {
    profile.value = await useNuxtApp().$api.getCustomerProfile()
    useAuthStore().isAuthenticated = true
  }

  const bonuses = ref<CustomerBonuses | null>()

  async function fetchBonuses() {
    try {
      bonuses.value = await useNuxtApp().$api.getCustomerBonuses()
    } catch (error) {
      bonuses.value = null
      throw error
    }
  }

  return {
    profile,
    bonuses,
    fetchProfile,
    fetchBonuses,
  }
})
