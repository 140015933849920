<script setup lang="ts">
import type { NuxtError } from '#app'

defineProps<{
  error: NuxtError
}>()

const route = useRoute()

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <div
    class="flex min-h-screen flex-col items-center justify-center space-y-4 p-6 text-center font-sans"
  >
    <h1 class="text-5xl font-bold">{{ error.statusCode }}</h1>
    <div>{{ error.message }}</div>
    <button
      v-if="error.statusCode === 404 && route.name !== 'index'"
      class="rounded bg-gray-200 px-4 py-1 dark:text-neutral-800"
      @click="handleError"
    >
      На главную
    </button>
  </div>
</template>
