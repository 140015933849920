import type { Category, Product } from '~/types/catalog'

export const useCatalogStore = defineStore('catalog', () => {
  const productModal = ref(false)

  const productId = ref<Product['id'] | null>(null)

  function showProductModal(id: Product['id']) {
    productModal.value = true
    productId.value = id
  }

  function hideProductModal() {
    productModal.value = false
    productId.value = null
  }

  const observableCategories = ref<Map<Category, IntersectionObserverEntry>>(
    new Map(),
  )

  const activeCategory = computed(() => {
    return Array.from(observableCategories.value)
      .filter(([, entry]) => entry.isIntersecting)
      .sort(([, a], [, b]) => {
        if (a.intersectionRatio !== b.intersectionRatio) {
          return a.intersectionRatio > b.intersectionRatio ? -1 : 1
        }

        if (a.time === b.time) {
          return 0
        }

        return a.time > b.time ? -1 : 1
      })[0]?.[0]
  })

  return {
    productModal,
    productId,
    observableCategories,
    activeCategory,
    showProductModal,
    hideProductModal,
  }
})
