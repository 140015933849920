import type { OrderType } from '~/types/order'
import type { Merchant } from './types'

export function getCoordinates(merchant: Merchant): [number, number] | null {
  if (!merchant.coordinates) return null

  const { latitude, lontitude } = merchant.coordinates

  if (latitude && lontitude) {
    return [Number(lontitude), Number(latitude)]
  }

  return null
}

export function makeMerchant(merchant: Merchant) {
  if (merchant.theme.fontFamily === 'Gotham Pro') {
    merchant.theme.fontFamily = 'Nunito'
  }

  const orderTypes: OrderType[] = [
    { label: 'Доставка', code: 'delivery' },
    { label: 'Самовывоз', code: 'pickup' },
    { label: 'В зале', code: 'dinein' },
  ]

  // merchant.theme.dark = true

  return {
    ...merchant,
    coordinates: getCoordinates(merchant),
    orderTypes: orderTypes.filter((type) =>
      merchant.orderTypes.includes(type.code),
    ),
    categories: merchant.categories.map((category) => ({
      ...category,
      image: category.image?.includes('no-photo') ? null : category.image,
    })),
  }
}
