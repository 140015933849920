import type { GeocodeByCoords, GeocodeByQuery } from '~/types/api'

export function useGeocode() {
  const geocoding = ref(false)
  const apiKey = useAppStore().merchant.services.ymaps.apiKey || ''

  async function geocode(payload: GeocodeByCoords | GeocodeByQuery) {
    try {
      geocoding.value = true

      return await useNuxtApp().$api.geocode({
        apiKey: apiKey,
        ...payload,
      })
    } finally {
      geocoding.value = false
    }
  }

  return {
    geocoding,
    geocode,
  }
}
