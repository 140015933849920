export default defineNuxtPlugin({
  async setup() {
    const authStore = useAuthStore()
    const customerStore = useCustomerStore()

    if (authStore.token) {
      await callOnce(async () => {
        try {
          await customerStore.fetchProfile()
        } catch {
          authStore.logout()
        }
      })
    }

    if (authStore.isAuthenticated) {
      onNuxtReady(() => {
        customerStore.fetchBonuses()
      })
    }
  },
})
