import type { Product, ProductSize, SelectedModifiers } from '~/types/catalog'

interface Payload {
  selectedModifiers?: SelectedModifiers | null
  selectedSizeId?: ProductSize['id'] | null
}

export const useProduct = (
  _product: MaybeRefOrGetter<Product>,
  payload?: Payload,
) => {
  const product = toValue(_product)
  const selectedModifiers = toRef<SelectedModifiers>(
    payload?.selectedModifiers || new Map(),
  )
  const selectedSizeId = toRef(
    (payload?.selectedSizeId || product.sizes?.[0]?.id)?.toString(),
  )

  const loading = ref(false)
  const removing = ref(false)

  const hasModifiers = computed<boolean>(() => Boolean(product.hasModifiers))
  const hasSizes = computed<boolean>(() => Boolean(product.sizes?.length))
  const isSimple = computed<boolean>(() => !isComplex.value)
  const isComplex = computed<boolean>(() =>
    Boolean(hasModifiers.value || (product.sizes && product.sizes.length > 1)),
  )

  const selectedSize = computed(
    () =>
      product.sizes?.find((size) => size.id == selectedSizeId.value) || null,
  )

  const selectedModifiersPrice = computed(() => {
    let price = 0

    selectedModifiers.value.forEach((group) => {
      group.forEach(({ quantity }, modifier) => {
        price += modifier.price * quantity
      })
    })

    return price
  })

  const currentPrice = computed(
    () =>
      (selectedSize.value?.price || product.price) +
      selectedModifiersPrice.value,
  )

  const cartCount = computed<number>(
    () => useCartStore().itemsCountById[product.id] || 0,
  )

  async function addToCart(quantity = 1) {
    try {
      loading.value = true

      validateModifiers()

      const payload = {
        product: product,
        quantity,
        size: selectedSize.value,
        modifiers: selectedModifiers.value,
      }

      await useNuxtApp().$api.addItemToCart(payload)

      useAppEventBus().emit('itemAddedToCart', { payload })

      await useCartStore().fetchCart()

      useNuxtApp().$toast({
        title: 'Товар добавлен в корзину',
        description: product.name,
      })

      useCatalogStore().hideProductModal()
    } catch (error) {
      if (error instanceof Error) {
        return useNuxtApp().$toast({
          title: 'Не удалось добавить в корзину',
          description: error.message,
          type: 'error',
        })
      }

      throw error
    } finally {
      loading.value = false
    }
  }

  async function removeFromCart() {
    try {
      removing.value = true

      const item = useCartStore().getCartItem(product.id)
      if (!item) return

      await useNuxtApp().$api.changeQuantity({ quantity: -1, item })
      await useCartStore().fetchCart()
    } catch (error) {
      handleError(error)
    } finally {
      removing.value = false
    }
  }

  async function showProductDetails() {
    const device = useDevice()

    if (device.isDesktop) {
      navigateTo(`/product/${product.id}`)
    } else {
      useCatalogStore().showProductModal(product.id)
    }
  }

  const { getModifiersCount } = useModifiers(selectedModifiers)

  function validateModifiers() {
    if (!hasModifiers.value) return

    const errors: string[] = []

    product.modifiers?.forEach((group) => {
      if (!group.min) return

      const selectedGroup = selectedModifiers.value.get(group)

      const count = selectedGroup ? getModifiersCount(selectedGroup) : 0

      if (count < group.min) {
        const message =
          group.min === 1
            ? `${group.name} - не выбрано`
            : `${group.name}: мин. кол-во - ${group.min}`

        errors.push(message)
      }
    })

    if (errors?.length) {
      throw new Error(errors.join('\n'))
    }
  }

  return {
    selectedSizeId,
    selectedSize,
    selectedModifiers,
    selectedModifiersPrice,
    hasModifiers,
    hasSizes,
    isSimple,
    isComplex,
    loading,
    removing,
    currentPrice,
    cartCount,
    addToCart,
    removeFromCart,
    showProductDetails,
  }
}
