import { load, type ReCaptchaInstance } from 'recaptcha-v3'

export default defineNuxtPlugin({
  setup() {
    const recaptchaInstance = shallowRef<ReCaptchaInstance | null>(null)
    const recaptchaLoaded = ref<boolean>(false)

    const siteKey = useRuntimeConfig().public.RECAPTCHA_SITE_KEY

    async function loadRecaptcha() {
      if (recaptchaLoaded.value) return

      recaptchaInstance.value = await load(siteKey, {
        autoHideBadge: true,
        useRecaptchaNet: true,
      })

      recaptchaLoaded.value = true
    }

    async function executeRecaptcha(action: string) {
      if (!recaptchaInstance.value) {
        throw new Error('Google recaptcha не загружена')
      }

      const token = await recaptchaInstance.value.execute(action)

      return { token }
    }

    return {
      provide: {
        recaptcha: {
          instance: recaptchaInstance,
          load: loadRecaptcha,
          execute: executeRecaptcha,
        },
      },
    }
  },
})
