import type { LocationQuery } from '#vue-router'

export default defineNuxtPlugin({
  setup() {
    callOnce(() => {
      const orderStore = useOrderStore()
      const query = useRoute().query
      orderStore.form.utm = getUtm(query)
    })
  },
})

function getUtm(query: LocationQuery): Record<string, string> {
  return Object.fromEntries(
    Object.entries(query)
      .filter(([key]) => key.startsWith('utm_'))
      .map(([key, value]) => [key, Array.isArray(value) ? value[0] : value])
      .filter(([_key, value]) => value),
  )
}
