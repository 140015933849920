import type {
  Product,
  ProductModifier,
  ProductModifierGroup,
  Sticker,
} from '~/types/catalog'
import type { Addon, AddonGroup, CartItem, Item, PriceData } from './types'
import type { LoadCart } from './types/actions'
import type { SelectedGift } from '~/types/cart'

export function removeEmpty<T extends object>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => Boolean(v)),
  ) as T
}

export function normalizePhoneNumber(phoneNumber: string): string {
  return phoneNumber.replace(/[^0-9,+]/g, '')
}

export function makeProduct(item: Item): Product {
  item.prices.sort((a, b) => getProductPrice(a) - getProductPrice(b))

  return {
    id: item.item_id,
    name: item.item_name,
    image: item.photo,
    description: item.item_description,
    price: getProductPrice(item.prices[0]),
    priceOld: getProductPriceOld(item.prices[0]),
    weight: item.item_massa ? `${item.item_massa} гр.` : null,
    sizes: getProductSizes(item.prices),
    stickers: makeStickers(item.stickers),
    modifiers: makeModifiers(item.addon_item),
    hasModifiers: Boolean(item.addon_item),
    nutritionalValue: getNutritionalValue(item),
    disabled: item.not_available === '2',
  }
}

export function getProductPrice(priceData?: PriceData): Product['price'] {
  if (!priceData) return 0
  return Number(priceData.discount_price || priceData.price) || 0
}

export function getProductPriceOld(priceData?: PriceData): Product['priceOld'] {
  if (priceData?.discount_price && priceData.discount) {
    return Number(priceData.price)
  }

  return null
}

export function getProductSizes(prices?: PriceData[]): Product['sizes'] {
  if (!Array.isArray(prices)) return null

  const sizes = prices
    .filter(
      (
        data,
      ): data is PriceData & {
        size: NonNullable<PriceData['size']>
      } => Boolean(data.size),
    )
    .map((data) => ({
      id: String(data.size_id),
      name: data.size,
      price: getProductPrice(data),
      priceOld: getProductPriceOld(data),
      weight: data.size_weight ? `${data.size_weight} гр.` : null,
    }))

  return sizes.length ? sizes : null
}

export function makeStickers(
  stickerData: Item['stickers'],
): Product['stickers'] {
  if (!stickerData?.sticker_name) return null

  const names = JSON.parse(stickerData?.sticker_name) as unknown
  const colors = JSON.parse(stickerData?.sticker_color) as unknown

  if (!Array.isArray(names) || !names.length) return null

  return names
    .map((name, index) => ({
      name,
      color: Array.isArray(colors) ? colors[index]?.toLowerCase() : null,
    }))
    .filter(
      (sticker): sticker is Sticker =>
        Boolean(sticker.name) && Boolean(sticker.color),
    )
}

export function makeCartItem(item: CartItem) {
  const modifiersPrice =
    item.sub_item?.reduce((sum, modifier) => {
      sum += modifier.addon_qty * Number(modifier.addon_price)
      return sum
    }, 0) || 0

  return {
    id: item.item_id,
    name: item.item_name,
    price: Number(item.discounted_price || item.normal_price) + modifiersPrice,
    priceOld: item.discount ? Number(item.normal_price) + modifiersPrice : null,
    quantity: Number(item.qty),
    sum: Number(item.total_price) + modifiersPrice,
    image: item.photo,
    ...(item.size_words && {
      size: {
        name: item.size_words,
      },
    }),
    modifiers: item.new_sub_item
      ? Object.fromEntries(
          Object.entries(item.new_sub_item).map(([groupName, items]) => {
            return [
              groupName,
              items.map((modifier) => ({
                name: modifier.addon_name,
                price: Number(modifier.addon_price),
                quantity: Number(modifier.addon_qty) / Number(item.qty),
              })),
            ]
          }),
        )
      : null,
  }
}

export function makeModifiers(groups: Item['addon_item']) {
  if (!Array.isArray(groups)) return null

  return groups.filter(productModifierGroupIsValid).map(
    (group): ProductModifierGroup => ({
      id: group.subcat_id,
      name: group.subcat_name,
      modifiers: group.sub_item.map(
        (item): ProductModifier => ({
          id: item.sub_item_id,
          name: item.sub_item_name,
          price: Number(item.price) || 0,
          image: item.photo?.includes('no-photo') ? null : item.photo,
        }),
      ),
      min:
        Number(group.multi_option_min_val) ||
        (group.require_addons === '2' ? 1 : 0),
      max:
        group.multi_option === 'one'
          ? 1
          : group.multi_option_val
            ? Number(group.multi_option_val)
            : null,
    }),
  )
}

export function productModifierGroupIsValid(
  group: AddonGroup,
): group is AddonGroup & {
  subcat_id: NonNullable<AddonGroup['subcat_id']>
  sub_item: Addon[]
} {
  return Boolean(
    group.subcat_id && Array.isArray(group.sub_item) && group.sub_item.length,
  )
}

export function getNutritionalValue(item: Item) {
  if (
    item.item_calories ||
    item.item_protein ||
    item.item_fats ||
    item.item_carbohydrates
  ) {
    return {
      calories: item.item_calories,
      proteins: item.item_protein,
      fats: item.item_fats,
      carbohydrates: item.item_carbohydrates,
    }
  }

  return null
}

export function getOrderStatusColor(status: string) {
  return (
    {
      новый: '#0ea5e9',
      отменен: '#ef4444',
      принят: '#14b8a6',
      оплачен: '#10b981',
      доставлен: '#22c55e',
      'на кухне': '#f97316',
      'в процессе': '#f59e0b',
      'передан курьеру': '#8b5cf6',
      'готов к выдаче': '#6366f1',
    }[status.toLowerCase()] || '#52525b'
  )
}

export function makeSelectedGift(
  data: LoadCart['details']['gift_cart'],
): SelectedGift | null {
  if (!data || Array.isArray(data)) {
    return null
  }

  return {
    id: data.gift_featured.gift_desc.presents_item_id,
    name: data.gift_featured.gift_desc.presents_item_name,
    image: data.gift_featured.gift_desc.presents_vendor_image,
    description: data.gift_featured.gift_desc.presents_description,
  }
}

export function makePromocode(jsonString?: string) {
  if (!jsonString) return null

  const promocodeData = JSON.parse(jsonString)

  return String(promocodeData.voucher_name) || null
}
