<template>
  <div
    class="w-fit max-w-full truncate rounded text-center leading-none"
    :class="{ sm: 'px-1.5 py-0.5 text-xs', lg: 'px-2 py-1 text-base' }[size]"
    :style="{ color: textColor, backgroundColor }"
  >
    <span>{{ sticker.name }}</span>
  </div>
</template>

<script setup lang="ts">
import chroma from 'chroma-js'
import type { Sticker } from '~/types/catalog'

const props = withDefaults(
  defineProps<{ sticker: Sticker; size?: 'sm' | 'lg' }>(),
  {
    size: 'sm',
  },
)

const backgroundColor = computed<string>(() => {
  return chroma.valid(props.sticker.color) ? props.sticker.color : 'black'
})

const textColor = computed<string>(() => {
  return chroma(backgroundColor.value).luminance() >= 0.5 ? '#000' : '#fff'
})
</script>
