<template>
  <textarea
    v-model="model"
    class="w-full resize-y rounded-md border border-gray-200 bg-white px-5 py-2 transition placeholder:text-gray-400 focus:border-primary focus:ring-4 focus:ring-primary/30 dark:border-neutral-800 dark:bg-neutral-950"
  />
</template>

<script setup lang="ts">
const model = defineModel<string>()
</script>
