import qs from 'qs'
import { ofetch } from 'ofetch'
import type { $Fetch } from 'ofetch'
import type { RequestParameters } from './types'
import { LegacyApiError } from './error'
import { removeEmpty } from './utils'

export class LegacyApi {
  fetch: $Fetch

  params: RequestParameters = {}

  constructor(baseURL: string) {
    this.fetch = ofetch.create({
      baseURL,
      responseType: 'json',
      query: {
        json: true,
        lang: 'ru',
        frontend: 'modern',
      },
      onRequest: ({ options }) => {
        options.query = removeEmpty({
          ...this.params,
          ...options.query,
        })

        if (options.body) {
          options.body = qs.stringify(options.body)
          options.headers = {
            ...options.headers,
            'Content-type': 'application/x-www-form-urlencoded',
          }
        }
      },
      onResponse: (context) => {
        if (context.response._data?.code !== 1) {
          throw new LegacyApiError(context)
        }
      },
    })
  }

  setParams(value: RequestParameters) {
    this.params = {
      ...this.params,
      ...value,
    }
  }
}
