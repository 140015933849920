export function handleError(error: unknown, title: string = 'Возникла ошибка') {
  if (error instanceof Error) {
    return useNuxtApp().$toast({
      title,
      description: error.message,
      type: 'error',
    })
  }

  throw error
}
