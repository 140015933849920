export default defineNuxtPlugin(() => {
  const route = useRoute()
  const cordovaStore = useCordovaStore()
  const appStore = useAppStore()
  const authStore = useAuthStore()

  if ('cordova' in route.query) {
    cordovaStore.isCordovaApp = true
  } else {
    return
  }

  const authToken = route.query.authToken

  if (authToken) {
    authStore.token = authToken.toString()
  }

  if (import.meta.client) {
    watch(
      () => authStore.token,
      (value) => {
        cordovaPostMessage({
          name: 'authToken',
          token: value,
        })
      },
    )

    cordovaPostMessage({
      name: 'appReady',
      color: useNuxtApp().$primaryColor.value,
      services: appStore.merchant.services,
    })

    window.addEventListener(
      'cordovaEvent',
      (event: CustomEvent<CordovaEventDetail>) => {
        const { name, data } = event.detail

        if (name === 'pushId') {
          useNuxtApp().$api.legacyApi.setParams({
            push_id: data.id,
          })
        }

        if (name === 'notificationClicked') {
          if (data.url) {
            onNuxtReady(() => {
              navigateTo(data.url)
            })
          }
        }
      },
    )
  }

  return {
    provide: {
      cordova: {
        openInSystemBrowser(url: string) {
          cordovaPostMessage({
            name: 'openInSystemBrowser',
            href: url,
          })
        },
        postMessage: cordovaPostMessage,
      },
    },
  }
})

function cordovaPostMessage(message: object) {
  try {
    window.webkit.messageHandlers.cordova_iab.postMessage(
      JSON.stringify(message),
    )
  } catch (error) {
    console.warn(error)
  }
}

declare global {
  interface Window {
    webkit: {
      messageHandlers: {
        cordova_iab: {
          postMessage: (message: string) => void
        }
      }
    }
    addEventListener(
      type: 'cordovaEvent',
      listener: (ev: CustomEvent) => unknown,
    ): void
  }
}

type CordovaEventDetail =
  | {
      name: 'onesignalId'
      data: { id: string | null }
    }
  | {
      name: 'pushToken'
      data: { token: string | null }
    }
  | {
      name: 'pushId'
      data: { id: string | null }
    }
  | {
      name: 'notificationClicked'
      data: { url?: string }
    }
  | {
      name: 'string'
      data: unknown
    }
