interface Params {
  deliveryZones?: boolean
  onMapClick?: (coords: number[]) => Promise<unknown>
}

export function useMap(
  mapContainerRef: Ref<HTMLElement | null>,
  params: Params = {},
) {
  const mapReady = ref(false)
  const mapInstance = shallowRef<ymaps.Map | null>(null)
  const deliveryZones = shallowRef<ymaps.GeoQueryResult>()
  const cityBounds = ref<number[][] | null>()

  whenever(mapContainerRef, (mapContainer) => {
    mapReady.value = false
    const { onLoaded } = useYmapsScript()

    onLoaded(async (ymaps) => {
      await ymaps.ready()

      mapInstance.value = createMap(mapContainer)

      mapInstance.value.events.add('click', (event) => {
        const coords: number[] = event.get('coords')
        params.onMapClick?.(coords)
      })

      if (params.deliveryZones) {
        try {
          deliveryZones.value = ymaps.geoQuery(await getDeliveryZones())
          deliveryZones.value.addToMap(mapInstance.value)
        } catch (error) {
          console.warn('Не удалось загрузить зоны доставки', error)
        }
      }

      await setBounds()

      mapReady.value = true
    })
  })

  function createMap(el: HTMLElement | string) {
    return new window.ymaps.Map(
      el,
      {
        center: [37.75, 55.73],
        zoom: 10,
        controls: [],
      },
      { suppressMapOpenBlock: true, yandexMapDisablePoiInteractivity: true },
    )
  }

  async function loadKml() {
    const merchantId = useAppStore().merchant.id
    const backetName = useRuntimeConfig().public.S3_BACKET_NAME

    const kmlUrl = `https://storage.yandexcloud.net/${backetName}/maps/merchant_${merchantId}.kml`

    const kmlContent = await $fetch<string>(kmlUrl, {
      responseType: 'text',
      cache: 'no-cache',
    })

    const { kml } = await import('@tmcw/togeojson')

    const featureCollection = kml(
      new DOMParser().parseFromString(kmlContent, 'text/xml'),
    )

    return featureCollection.features
  }

  async function getDeliveryZones() {
    const features = await loadKml()

    return features
      .map((feature) => {
        if (
          feature.geometry &&
          feature.geometry.type === 'Polygon' &&
          feature.properties?.disabled != '1'
        ) {
          return new window.ymaps.GeoObject(
            {
              geometry: feature.geometry,
            },
            {
              ...feature.properties,
              interactivityModel: 'default#silent',
            },
          )
        }
      })
      .filter((item): item is ymaps.GeoObject => Boolean(item))
  }

  async function setDeliveryZonesBounds() {
    const bounds = deliveryZones.value?.getBounds()
    if (bounds) {
      mapInstance.value?.setBounds(bounds)
    }
  }

  async function setCityBounds() {
    const bounds = await getCityBounds()

    if (bounds) {
      mapInstance.value?.setBounds(bounds)
      mapInstance.value?.setZoom(10)
    }
  }

  async function getCityBounds() {
    if (cityBounds.value) {
      return cityBounds.value
    }

    const city = useAppStore().merchant.city
    if (!city) return

    const res = await window.ymaps.geocode(city, { results: 1 })

    return res.geoObjects.get(0).geometry?.getBounds()
  }

  async function setBounds() {
    if (useAppStore().merchant.deliveryZonesEnabled) {
      setDeliveryZonesBounds()
    } else {
      await setCityBounds()
    }
  }

  function searchDeliveryZone(coords: number[]) {
    const deliveryZone = deliveryZones.value?.searchContaining(coords).get(0)

    if (deliveryZone) {
      const options = deliveryZone.options.getAll() as Record<string, string>
      return options.name
    }
  }

  return {
    mapReady,
    mapInstance,
    deliveryZones,
    searchDeliveryZone,
    setDeliveryZonesBounds,
    setCityBounds,
    setBounds,
  }
}
