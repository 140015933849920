export default defineNuxtPlugin(() => {
  const appStore = useAppStore()

  useEcommerce()

  const yandexMetricaCounterId =
    appStore.merchant.services.yandexMetrica.counterId

  if (yandexMetricaCounterId) {
    useYandexMetrica(Number(yandexMetricaCounterId))
  }

  const topMailRuId = appStore.merchant.services.topMailRu.id

  if (topMailRuId) {
    useTopMailRu(topMailRuId)
  }

  if (
    useCordovaStore().isCordovaApp &&
    appStore.merchant.services.yandexAppMetrica.apiKey
  ) {
    useAppMetrica()
  }
})
