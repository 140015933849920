import type {
  ProductModifier,
  ProductModifierGroup,
  SelectedModifiers,
} from '~/types/catalog'

export const useModifiers = (selectedModifiers: Ref<SelectedModifiers>) => {
  function addModifier(
    modifierGroup: ProductModifierGroup,
    modifier: ProductModifier,
  ) {
    if (!selectedModifiers.value.has(modifierGroup)) {
      selectedModifiers.value.set(modifierGroup, new Map())
    }

    const group = selectedModifiers.value.get(modifierGroup)

    if (!group) return

    const modifiersCount = getModifiersCount(group)

    if (modifierGroup.max && modifierGroup.max <= modifiersCount) return

    const quantity = group.get(modifier)?.quantity

    group.set(modifier, { quantity: quantity ? quantity + 1 : 1 })
  }

  function removeModifier(
    modifierGroup: ProductModifierGroup,
    modifier: ProductModifier,
  ) {
    const group = selectedModifiers.value.get(modifierGroup)

    const quantity = group?.get(modifier)?.quantity

    if (quantity) {
      group?.set(modifier, { quantity: quantity - 1 })
    }
  }

  function clearModifierGroup(modifierGroup: ProductModifierGroup) {
    selectedModifiers.value.set(modifierGroup, new Map())
  }

  function getModifiersCount(
    group: Map<ProductModifier, { quantity: number }>,
  ) {
    return Array.from(group.entries()).reduce((count, [, { quantity }]) => {
      return count + quantity
    }, 0)
  }

  return {
    addModifier,
    removeModifier,
    clearModifierGroup,
    getModifiersCount,
  }
}
