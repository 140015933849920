import { addCollection } from "@iconify/vue"
let _initialized = false
export function init() {
  if (_initialized)
    return
  const collections = JSON.parse("[{\"prefix\":\"ri\",\"icons\":{\"close-fill\":{\"width\":24,\"height\":24,\"body\":\"<path fill=\\\"currentColor\\\" d=\\\"m12 10.587l4.95-4.95l1.414 1.414l-4.95 4.95l4.95 4.95l-1.415 1.414l-4.95-4.95l-4.949 4.95l-1.414-1.415l4.95-4.95l-4.95-4.95L7.05 5.638z\\\"/>\"},\"add-line\":{\"width\":24,\"height\":24,\"body\":\"<path fill=\\\"currentColor\\\" d=\\\"M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z\\\"/>\"}}}]")
  for (const collection of collections) {
    addCollection(collection)
  }
  _initialized = true
}