import { FetchError } from 'ofetch'

export default defineNuxtPlugin({
  async setup() {
    const config = useRuntimeConfig()
    const route = useRoute()
    const appStore = useAppStore()

    let domain: string

    const demoDomain = (route.query.domain || route.query.demo)?.toString()

    if (demoDomain) {
      domain = demoDomain
    } else if (import.meta.dev) {
      domain = config.public.DEV_DOMAIN
    } else {
      domain = useRequestURL().host
    }

    try {
      await callOnce(async () => {
        await appStore.fetchMerchant(domain)
        appStore.merchantLoaded = true
      })
    } catch (error) {
      if (error instanceof FetchError === false) {
        throw error
      }

      if (error.status === 402 && import.meta.client) {
        appStore.cookieMerchantKey = null
      }

      throw createError({
        message: error.data.message || error.message,
        status: error.status,
      })
    }

    useHead({
      link: [
        {
          rel: 'icon',
          href: appStore.merchant.favicon,
        },
      ],
      script: [{ innerHTML: appStore.merchant.customContent.js }],
      meta: appStore.merchant.customContent.metaTags,
    })

    useSeoMeta({
      ...appStore.merchant.seo,
    })
  },
})
