import { ofetch } from 'ofetch'
import type { $Fetch } from 'ofetch'

export class ModernApi {
  fetch: $Fetch

  merchantKey: string | null = null

  constructor(baseURL: string) {
    this.fetch = ofetch.create({
      baseURL,
      responseType: 'json',
      headers: {
        Accept: 'application/json',
        ...(this.merchantKey && { merchantKey: this.merchantKey }),
      },
    })
  }
}
