export function useEcommerce() {
  if (!import.meta.client) return

  window.dataLayer = window.dataLayer || []

  const bus = useAppEventBus()

  bus.on('productView', ({ product, category }) => {
    window.dataLayer.push({
      ecommerce: {
        detail: {
          products: [
            {
              id: product.id,
              name: product.name,
              price: product.price,
              ...(category && { category: category.name }),
            },
          ],
        },
      },
    })
  })

  bus.on('itemAddedToCart', ({ payload }) => {
    window.dataLayer.push({
      ecommerce: {
        add: {
          products: [
            {
              id: payload.product.id,
              name: payload.product.name,
              price: payload.product.price,
              quantity: payload.quantity,
              ...(payload.size && { variant: payload.size.name }),
            },
          ],
        },
      },
    })
  })

  bus.on('cartItemQuantityChanged', ({ payload }) => {
    window.dataLayer.push({
      ecommerce: {
        [payload.quantity < 0 ? 'remove' : 'add']: {
          products: [
            {
              id: payload.item.id,
              name: payload.item.name,
              price: payload.item.price,
              quantity: Math.abs(payload.quantity),
              ...(payload.item.size && { variant: payload.item.size.name }),
            },
          ],
        },
      },
    })
  })

  bus.on('orderCreated', ({ order, cart }) => {
    window.dataLayer.push({
      ecommerce: {
        purchase: {
          actionField: {
            id: order.id,
            ...(cart.promocode && { coupon: cart.promocode }),
          },
          products: cart.items.map((item) => ({
            id: item.id,
            name: item.name,
            price: item.price,
            quantity: item.quantity,
            ...(item.size && { variant: item.size.name }),
            ...(item.priceOld && {
              discount: +Number(item.priceOld - item.price).toFixed(2),
            }),
          })),
        },
      },
    })
  })
}

declare global {
  interface Window {
    dataLayer: Array<object>
  }
}
