export function useYmapsScript() {
  const appStore = useAppStore()
  const apiKey = computed(() => appStore.merchant.services.ymaps.apiKey)

  return useScript(
    {
      src: `https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=${apiKey.value}&coordorder=longlat`,
    },
    {
      use() {
        return window.ymaps
      },
    },
  )
}
