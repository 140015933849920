import mitt from 'mitt'
import type {
  AddItemToCartPayload,
  Cart,
  ChangeQuantityPayload,
} from '~/types/cart'
import type { Category, Product } from '~/types/catalog'
import type { CreatedOrder } from '~/types/order'

type Events = {
  orderCreated: {
    order: CreatedOrder
    cart: Cart
  }
  merchantContactPhoneClicked: {
    phone: string
  }
  downloadAppBannerClicked: unknown
  downloadAppButtonClicked: unknown
  customerRegistered: unknown
  cartView: unknown
  itemAddedToCart: {
    payload: AddItemToCartPayload
  }
  cartItemQuantityChanged: {
    payload: ChangeQuantityPayload
  }
  productView: {
    product: Product
    category?: Category
  }
}

const emitter = mitt<Events>()

export function useAppEventBus() {
  return emitter
}
