export default defineAppConfig({
  icon: {
    class: 'icon', // default <Icon> class applied
    // size: '24px', // default <Icon> size applied
    // mode: 'css', // default <Icon> mode applied
    // aliases: {
    //   nuxt: 'logos:nuxt-icon',
    // },
  },
})
