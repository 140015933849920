export default defineNuxtPlugin(() => {
  const router = useRouter()
  const device = useDevice()

  router.beforeEach((to) => {
    const layout = to.meta.layout

    if (!layout && layout !== false) {
      to.meta.layout = device.isDesktop ? 'desktop' : 'mobile'
    }
  })
})
